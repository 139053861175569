import { all, takeLatest } from 'redux-saga/effects'

import {
  workerSignOut,
  workerCheckAuthentication,
  workerSignIn
} from './sagas'

import { AuthConstants } from './types'

export function* watcherCheckAuthentication() {
  yield takeLatest(AuthConstants.ACTION_CHECK_AUTHENTICATION, workerCheckAuthentication)
}

export function* watcherSignIn() {
  yield takeLatest(AuthConstants.ACTION_SIGN_IN, workerSignIn)
}

export function* watcherSignOut() {
  yield takeLatest(AuthConstants.ACTION_SIGN_OUT, workerSignOut)
}


export default function* authWatchers() {
  yield all([
    watcherCheckAuthentication(),
    watcherSignIn(),
    watcherSignOut()
  ])
}
