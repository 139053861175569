import produce from 'immer'
import {AnyAction} from 'redux'

import {PowerBiConstants, PowerBiStore} from './types'

const POWERBI_STORE: PowerBiStore = {
    gettingUrl: false,
    url: ''
}

export default(store = POWERBI_STORE, action : AnyAction) => produce(store, draft => {
    const {payload} = action
    switch (action.type) {
        case PowerBiConstants.REDUCER_CLEAR_POWERBI:
            Object.assign(draft, POWERBI_STORE)
            break
        case PowerBiConstants.REDUCER_SET_POWERBI:
            Object.assign(draft, payload)
            break
        default:
            break
    }
})
