import React from 'react'
import { connect } from 'react-redux'
import { ApplicationStore } from 'store/types'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

// Resources
// import './styles.scss'
import logoRegular from 'resources/img/beeftrader-grass-logo-regular.png'

// Types
import { Props } from './types'
import { Themes } from 'store/main/types'
import { Dispatch, bindActionCreators } from 'redux'

// Methods
import { setTheme } from 'store/main/actions'

const NotFound = (props: Props) => {
  const { t } = useTranslation()

  return (
    <div id={'notfound'} className={'auth-container'}>
      <div className={'auth-picture-container'}>
        <img
          src={logoRegular}
          className={'auth-picture'}
        />
      </div>
      <aside className='auth-panel'>
        <div className='auth-settings-row'>
          {/* <ThemeToggle />
          <LocaleSelector /> */}
        </div>
        <div className='auth-form-container'>
          <div className='auth-paper'>
            <img
              src={logoRegular}
              className={'auth-logo'}
            />
            <div className='auth-row'>
              <text className={'not-found-text'}>{t('not_found')}</text>
            </div>
            <NavLink id='not-found-link' to='/' className='not-found-link'>
              {t('go_home')}
            </NavLink>
            <div className='auth-form'>
              <div className='auth-row'></div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTheme
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(NotFound))
