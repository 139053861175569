
export type PowerBiStore = {
  gettingUrl: boolean
  url: string
}


export enum PowerBiConstants{
  GET_POWERBI = 'GET_POWERBI',
  REDUCER_CLEAR_POWERBI = 'REDUCER_CLEAR_POWERBI',
  REDUCER_SET_POWERBI = 'REDUCER_SET_POWERBI'

}