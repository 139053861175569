import { Themes } from "store/main/types"

export type Color = {
    light: string
    dark: string
  }
  export enum Colors {
    accent = 'accent',
    accentPlusLight = 'accentPlusLight',
    accentDark = 'accentDark',
    accentLight = 'accentLight',
    black = 'black',
    blue = 'blue',
    error = 'error',
    foreground = 'foreground',
    gray = 'gray',
    grayDarkLight = 'grayDarkLight',
    grayDark = 'grayDark',
    grayLightMedium = 'grayLightMedium',
    grayLight = 'grayLight',
    help = 'help',
    white = 'white'
  }
  
  const colors: { [key: string]: Color } = {
    accent: {
      light: '#395f1c',
      dark: '#395f1c'
    },
    accentPlusLight: {
      light: '#ef7343',
      dark: '#ef7343'
    },
    accentLight: {
      light: '#E66430',
      dark: '#E66430'
    },
    accentDark: {
      light: '#9E4521',
      dark: '#9E4521'
    },
    background: {
      light: '#fff',
      dark: '#303030'
    },
    black: {
      light: '#000',
      dark: '#000'
    },
    blue: {
      light: '#00b',
      dark: '#00b'
    },
    error: {
      light: '#e00',
      dark: '#e00'
    },
    foreground: {
      light: '#303030',
      dark: '#fff'
    },
    gray: {
      light: '#aaa',
      dark: '#aaa'
    },
    grayLightMedium: {
      light: '#cbcbcb',
      dark: '#cbcbcb'
    },
    grayLight: {
      light: '#eee',
      dark: '#eee'
    },
    grayDarkLight: {
      light: '#8a8a8a',
      dark: '#8a8a8a'
    },
    grayDark: {
      light: '#303030',
      dark: '#303030'
    },
    help: {
      light: '#2CD1B5',
      dark: '#2CD1B5'
    },
    white: {
      light: '#fff',
      dark: '#fff'
    }
  }
  
  export default (theme: Themes, color: Colors) =>
    theme === Themes.LIGHT ? colors[color].light : colors[color].dark
  