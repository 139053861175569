import React from 'react'
import { useTranslation } from 'react-i18next'
import { Popover, List, ListItem, ListItemIcon, ListItemText, IconButton } from '@material-ui/core'
import { AccountCircle } from '@material-ui/icons'

// Resources
import './styles.scss'

// Types
import { Props } from './types'
import { withRouter } from 'react-router-dom'

const ProfileButton = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState<boolean>(false)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const id = open ? 'menu-settings' : undefined
  const { t } = useTranslation()
  return (
    <div>
      <IconButton onClick={handleMenu}>
        <AccountCircle color={'secondary'} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          // subheader={
          //   <ListSubheader component='div' id='nested-list-subheader'>
          //     {t('settings')}
          //   </ListSubheader>
          // }
          className='setting-menu-root'
        >
          {props.items.map(item => (
            <ListItem button key={item.title} onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={t(item.title)} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

export default withRouter(ProfileButton)
