import { Themes, MainConstants, Locales } from './types'

export const setLocale = (locale: Locales) => {
  return {
    type: MainConstants.ACTION_SET_LOCALE,
    payload: {
      locale
    }
  }
}

export const checkSettings = () => {
  return {
    type: MainConstants.ACTION_CHECK_SETTINGS,
    payload: {}
  }
}

export const setTheme = (theme: Themes) => {
  console.log('pp')

  return {
    type: MainConstants.REDUCER_FETCH_MAIN_DATA,
    payload: {
      theme
    }
  }
}
