import React, { Suspense } from 'react'
import { Provider } from 'react-redux'

// Resources
import store from 'store'
import 'styles/reset.scss'
import 'styles/index.scss'
import 'helpers/i18n'

// Components
import Router from '../Router'

const Loading = () => (
  <div>
    <div>Loading...</div>
  </div>
)

const App = () => (
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <Router />
    </Suspense>
  </Provider>
)

export default App
