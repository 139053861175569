import React from 'react'
import { Route } from 'react-router-dom'

import Contact from 'pages/Contact'

const PublicRoutes = [
  <Route key='/contact' exact path='/contact'>
    <Contact />
  </Route>
]

export default PublicRoutes
