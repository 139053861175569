import { combineReducers, applyMiddleware, Store, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { ApplicationStore } from './types'

import auth from './auth/reducers'
import main from './main/reducers'
import powerbi from './powerbi/reducers'

import authWatchers from './auth/watchers'
import mainWatchers from './main/watchers'
import powerbiWatchers from './powerbi/watchers'

const reducers = combineReducers({
  auth,
  main,
  powerbi
})

const sagaMiddleware = createSagaMiddleware()
const store: Store<ApplicationStore> = createStore(reducers, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(authWatchers)
sagaMiddleware.run(mainWatchers)
sagaMiddleware.run(powerbiWatchers)

export default store
