export type Exception = {
  code: ExceptionCodes
  source: string
  additionalInfo?: any
}

export enum ExceptionCodes {
  INVALID_ENVIRONMENT_VARIABLE = 98,
  UNEXPECTED_ERROR = 99
}

export enum ExceptionMessageText {
  INVALID_ENVIRONMENT_VARIABLE = 'unexpected_error',
  UNEXPECTED_ERROR = 'unexpected_error'
}

export enum ExceptionMessageTitle {
  INVALID_ENVIRONMENT_VARIABLE = 'unexpected_error',
  UNEXPECTED_ERROR = 'unexpected_error'
}
