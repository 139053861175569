import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { ApplicationStore } from 'store/types'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

// Resources
import './styles.scss'

// Types
import { Props } from './types'
import { Dispatch, bindActionCreators } from 'redux'

// Methods
import { setTheme } from 'store/main/actions'
import { getPowerBi } from 'store/powerbi/actions'

const PowerBi = (props: Props) => {
  const { t } = useTranslation()

  useEffect(() => {
      props.getPowerBi()
    }, [])
  return (
    <div id={'notfound'} className={'powerbi'}>
    <iframe  width="100%"
    src={props.url} 
   ></iframe>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  url: store.powerbi.url
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTheme,
      getPowerBi
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(PowerBi))
