import produce from 'immer'
import { AnyAction } from 'redux'

import { AuthStore, AuthConstants } from './types'

const INITIAL_STORE: AuthStore = {
  credentials: undefined,
  isAuthenticated: false,
  isAuthenticating: false,
  isCheckingAuthentication: false,
  isCheckingRecoveryToken: false,
  requestingRecoveryEmail: false,
  requestEmailStatus: undefined
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, draft => {
    const { payload } = action
    switch (action.type) {
      case AuthConstants.REDUCER_CLEAR_AUTH_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      case AuthConstants.REDUCER_SET_AUTH_DATA:
        Object.assign(draft, payload)
        break
      default:
        break
    }
  })
