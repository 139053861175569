import config from 'config'
import axios from 'axios'
// Classes
import Exception from 'helpers/Exception'

// Types
import { Credentials } from './types'

export const requestCredentials = async (email: string, password: string): Promise<Credentials> => {
  
  const apiUrl = config.api +'auth/signin'
  try {
    const dataAuth = { email: email, password: password, app:'beeftradergrass',env:'web'};
    let data = await axios.post(apiUrl, dataAuth)
    .then(response => response.data)
    .catch(error => {

      throw new Exception(500, 'api/auth/getCredentials', error)
    })
    console.log(data)
    if (!data) {
        throw new Exception(
          500,
          'api/auth/requestCredentials'
        )
      }

    const credentials = {
      token: data
    }
    return credentials
  } catch (error) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(500, 'api/auth/getCredentials', error)
  }
}

export const validateAuthentication = async (
  authData: Credentials
): Promise<Boolean> => {
  const { token} = authData
  const apiUrl = config.api+'auth/validate'
  
  try {
  
    const headers = { 
      'Authorization': `Bearer ${token}`
  };
  let data = await axios.get(apiUrl,{headers})
    console.log(data)
    return data.status!==200 ? false: true
  } catch (error) {
    throw new Exception(500, 'api/auth/getCredentials', error)
  }
}

export const getPowerBI = async (
  authData: Credentials
): Promise<string | null> => {
  const { token} = authData
  const apiUrl = config.api+'auth/get-url'
  
  try {   
    const headers = { 
      'Authorization': `Bearer ${token}`
  };
  let data = await axios.get(apiUrl,{headers})
    return data.data
  } catch (error) {
    throw new Exception(500, 'api/auth/getCredentials', error)
  }
}
