import { Credentials } from "api/auth/types"
import { Locales, Themes } from "store/main/types"
import { AuthData, StorageKeys } from "./types"

export const setItemInStorage = (
    key: StorageKeys,
    value?: Credentials | Themes | Locales | string | null
  ) => {
    if (!value) {
      localStorage.removeItem(key)
      return
    }
    localStorage.setItem(key, JSON.stringify(value))
  }
  
export const getItemFromStorage = (
    key: StorageKeys
  ): AuthData | Themes | Locales | undefined => {
    try {
      console.log('KEYY: ',key)
      switch (key) {
        case StorageKeys.AuthData: {
          const tokenJSONStr = localStorage.getItem(StorageKeys.Token)
          if (!tokenJSONStr) return
          const token = JSON.parse(tokenJSONStr)
          return {
            token
          }
        }
        case StorageKeys.Theme: {
          const themeJSONStr = localStorage.getItem(StorageKeys.Theme)
          if (!themeJSONStr) {
            setItemInStorage(StorageKeys.Theme, Themes.LIGHT)
            return Themes.LIGHT
          }
          const theme: Themes = JSON.parse(themeJSONStr)
          if (theme !== Themes.LIGHT && theme !== Themes.DARK) {
            setItemInStorage(StorageKeys.Theme, Themes.LIGHT)
            return Themes.LIGHT
          }
          return theme
        }
        case StorageKeys.Locale: {
          const localeJSONStr = localStorage.getItem(StorageKeys.Locale)
          if (!localeJSONStr) {
            setItemInStorage(StorageKeys.Locale, Locales.pt)
            return Locales.pt
          }
          const locale: Locales = JSON.parse(localeJSONStr)
          if (locale !== Locales.en && locale !== Locales.pt) {
            setItemInStorage(StorageKeys.Locale, Locales.pt)
            return Locales.pt
          }
          return locale
        }
        default:
          return
      }
    } catch (error) {
      throw error
    }
  }
  