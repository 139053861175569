import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'

// Types
import { Props } from './types'

// Components
import Loading from 'pages/Loading'
import SignIn from 'pages/SignIn'
import { ApplicationStore } from 'store/types'

const AuthRoute = (parentProps: Props) => {  

  return (
    <Route
      exact
      path={parentProps.path}
      render={props => (parentProps.isCheckingAuthentication ? <Loading /> : <SignIn {...props} />)}
    />
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  isCheckingAuthentication: store.auth.isCheckingAuthentication
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStoreToProps, mapDispatchToProps)(AuthRoute)
