import PowerBi from 'pages/PowerBi'
import React from 'react'
import { Route, Redirect } from 'react-router'


export default [
  <Route path='/' component={PowerBi} />,
  <Route path='*' key={'*'}>
    <Redirect to={'/not-found'} />
  </Route>
]
