import produce from 'immer'
import { AnyAction } from 'redux'

import { MainStore, MainConstants, Themes, Locales } from './types'

const INITIAL_STORE: MainStore = {
  locale: Locales.en,
  message: undefined,
  theme: Themes.LIGHT,
  timeZone: ''
}

export default (store = INITIAL_STORE, action: AnyAction) =>
  produce(store, draft => {
    const { payload } = action
    switch (action.type) {
      case MainConstants.REDUCER_FETCH_MAIN_DATA:
        Object.assign(draft, payload)
        break
      default:
        break
    }
  })
