import { createMuiTheme } from '@material-ui/core/styles'
import colors, { Colors } from './variables/colors'
import { Themes } from 'store/main/types'

export const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: colors(Themes.LIGHT, Colors.accent)
    },
    secondary: {
      main: colors(Themes.LIGHT, Colors.white)
    },
    error: {
      main: colors(Themes.LIGHT, Colors.error)
    }
  }
})

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: colors(Themes.LIGHT, Colors.black)
    },
    background: {
      default: colors(Themes.LIGHT, Colors.grayDark)
    },
    secondary: {
      main: colors(Themes.LIGHT, Colors.white)
    },
    error: {
      main: colors(Themes.LIGHT, Colors.error)
    },
    text: {
      primary: colors(Themes.LIGHT, Colors.white)
    }
  }
})
