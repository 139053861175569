import { put, call, Effect } from 'redux-saga/effects'

// Constants
import Codes from 'helpers/Codes'

// Types
import { AuthConstants } from './types'
import { AuthData, StorageKeys } from 'storage/types'
import { MainConstants } from 'store/main/types'

// Classes
import Message from 'helpers/Message'
import Exception from 'helpers/Exception'

// Methods
import { setItemInStorage, getItemFromStorage } from 'storage'

import {
  requestCredentials,
  validateAuthentication,
} from 'api/auth'

function* putAuthData(payload: any) {
  yield put({
    type: AuthConstants.REDUCER_SET_AUTH_DATA,
    payload
  })
}

function* putMainData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerSignIn(action: Effect) {
  yield call(putAuthData, { isAuthenticating: true })

  try {
    const { payload } = action
    const token = yield call(requestCredentials, payload.email, payload.password)
    console.log("TOKEN 0:", token)
    setItemInStorage(StorageKeys.Token, token.token)
    yield call(putAuthData, { token, isAuthenticating: false, isAuthenticated: true })
  } catch (error) {
    console.log(error)
    let message = null
    if (error instanceof Exception) {
      message = error.getMessage()
    } else {
      message = 'unexpected_error'
    }
    yield call(putAuthData, { isAuthenticating: false })
    yield call(putMainData, { message })
  }
}

export function* workerCheckAuthentication(action?: Effect) {
  const checkingFromRouter = action?.payload.checkingFromRouter
  console.log("CHECK AUTHENTICATION 0:", checkingFromRouter)
  if (checkingFromRouter) {
    yield call(putAuthData, {
      isCheckingAuthentication: true
    })
  }
  try {
    const authData:AuthData = getItemFromStorage(StorageKeys.AuthData) as AuthData
    console.log("CHECK AUTHENTICATION 1:",authData)
    if (authData) {
      const credentials = yield call(validateAuthentication, authData)

      console.log("CREDENTIALS 2: ",credentials)
        if (credentials) {
        yield call(putAuthData, {
          isAuthenticated: true,
          isAuthenticating: false,
          isCheckingAuthentication: false
        })
      }
      else{
        yield call(putAuthData, {
          isAuthenticated: false,
          isAuthenticating: false,
          isCheckingAuthentication: false
        })
      }
    } 
    else{
      yield call(putAuthData, {
        isAuthenticated: false,
        isAuthenticating: false,
        isCheckingAuthentication: false
      })
    }
  } catch (error) {
    if (checkingFromRouter) {
      yield call(putAuthData, {
        isAuthenticating: false,
        isAuthenticated: false,
        isCheckingAuthentication: false
      })
    }
  }
}

export function* workerSignOut(action: Effect) {
  try {
    const { logout } = action.payload
    setItemInStorage(StorageKeys.Token)
    yield put({
      type: AuthConstants.REDUCER_CLEAR_AUTH_DATA
    })
    yield put({
      type: MainConstants.REDUCER_CLEAR_MAIN_DATA
    })
    if (!logout) {
      yield call(putMainData, {
        payload: {
          message:'signin_again'
        }
      })
    }
  } catch (error) {
    const ex = new Exception(
      500,
      'sagas/auth/sagas/workerSignOut',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putMainData, {
      message
    })
  }
}
