/**
 * @class
 * Exception class
 */

// Types
import { ExceptionCodes, ExceptionMessageText, ExceptionMessageTitle } from './types'
import { MessageType, MessageAlertMode } from '../Message/types'

// Classes
import Message from '../Message/'

export default class Exception {
  /**
   * @constructs
   * @param {ExceptionCodes} code
   * @param {string} source
   * @param {string} additionalInfo
   */

  code: ExceptionCodes
  source: string
  additionalInfo: string

  constructor(code: ExceptionCodes, source: string, additionalInfo?: any) {
    this.code = code
    this.source = source
    this.additionalInfo = additionalInfo
  }

  /**
   * @function
   * Returns the Message instance of exception
   * @returns {Message}
   */
  getMessage = () => {
    return new Message(
      MessageAlertMode.TOAST,
      this.source,
      this.getMessageText(this.code),
      this.getMessageTitle(this.code),
      this.getMessageType(this.code),
      this.additionalInfo
    )
  }

  /**
   * @function
   * Returns the text of Message
   * @param {ExceptionCodes} code Exception code
   * @returns {ExceptionMessageText}
   */
  getMessageText = (code: ExceptionCodes): ExceptionMessageText => {
    switch (code) {
      case ExceptionCodes.UNEXPECTED_ERROR:
        return ExceptionMessageText.UNEXPECTED_ERROR
      default:
        return ExceptionMessageText.UNEXPECTED_ERROR
    }
  }

  /**
   * @function
   * Returns the title of Message
   * @param {ExceptionCodes} code Exception code
   * @returns {ExceptionMessageTitle}
   */
  getMessageTitle = (code: ExceptionCodes): ExceptionMessageTitle => {
    switch (code) {
      case ExceptionCodes.UNEXPECTED_ERROR:
        return ExceptionMessageTitle.UNEXPECTED_ERROR
      default:
        return ExceptionMessageTitle.UNEXPECTED_ERROR
    }
  }

  /**
   * @function
   * Returns the type of Message
   * @param {ExceptionCodes} code Exception code
   * @returns {string}
   */
  getMessageType = (code: ExceptionCodes): MessageType => {
    switch (code) {
      case ExceptionCodes.UNEXPECTED_ERROR:
        return MessageType.ERROR
      default:
        return MessageType.INFO
    }
  }
}
