import {put, call, Effect, select} from 'redux-saga/effects'

// Constants
import Codes from 'helpers/Codes'

// Types
import {PowerBiConstants} from './types'
import {AuthData, StorageKeys} from 'storage/types'
import {MainConstants} from 'store/main/types'

// Classes
import Message from 'helpers/Message'
import Exception from 'helpers/Exception'

// Methods
import {setItemInStorage, getItemFromStorage} from 'storage'

import {getPowerBI} from 'api/auth'
import {workerCheckAuthentication} from 'store/auth/sagas'

function* putPowerBiData (payload: any) {
    yield put({type: PowerBiConstants.REDUCER_SET_POWERBI, payload})
}

function* putMainData (payload: any) {
    yield put({type: MainConstants.REDUCER_SET_MAIN_DATA, payload})
}

export function* workerGetPowerBI (action: Effect) {
    yield call(putPowerBiData, {gettingUrl: true})

    try {
        const {payload} = action
        const authData: AuthData = getItemFromStorage(StorageKeys.AuthData)as AuthData
        console.log("CHECK POWERBI 1:", authData)
        if (authData) {
            const {url} = yield select(s => s.powerbi)
            if (!url) {
                const urlApi = yield call(getPowerBI, authData)
                yield call(putPowerBiData, {
                    url: urlApi,
                    gettingUrl: false
                })
            }
        }


    } catch (error) {
        console.log(error)
        let message = null
        if (error instanceof Exception) {
            message = error.getMessage()
        } else {
            message = 'unexpected_error'
        }
        yield call(putPowerBiData, {gettingUrl: false})
        yield call(putMainData, {message})
    }
}
