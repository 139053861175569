import Message from '../../helpers/Message'

export type MainStore = {
  message?: Message
  theme: Themes
  locale: Locales
  timeZone: string
}

export enum Locales {
  en = 'en',
  pt = 'pt'
}

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum MainConstants {
  ACTION_SET_THEME = 'ACTION_SET_THEME',
  ACTION_SET_LOCALE='ACTION_SET_LOCALE',
  ACTION_CHECK_SETTINGS='ACTION_CHECK_SETTINGS',
  REDUCER_FETCH_MAIN_DATA = 'REDUCER_FETCH_MAIN_DATA',
  REDUCER_SET_MAIN_DATA = 'REDUCER_SET_MAIN_DATA',
  REDUCER_CLEAR_MAIN_DATA='REDUCER_CLEAR_MAIN_DATA'
}
