import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { ExitToApp } from '@material-ui/icons'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'
import { Themes } from 'store/main/types'
import { PopMenuItems } from 'components/ProfileButton/types'

// Components
import ProfileButton from 'components/ProfileButton'
import { AppBar } from '@material-ui/core'

// Methods
import { signOut } from 'store/auth/actions'

const HeaderBar = (props: Props) => {
  const items: PopMenuItems[] = [
    {
      title: 'auth:sign_out',
      icon: <ExitToApp color={'primary'} />,
      onClick: () => {
        props.signOut()
      }
    }
  ]

  return (
    <div className={'headerbar'}>
      <AppBar position='fixed' color={'primary'}>
        <div className='headerbar-content'>
          <ProfileButton items={items} />
        </div>
      </AppBar>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signOut
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(HeaderBar))
