import React from 'react'
import { connect } from 'react-redux'
import { Switch } from 'react-router-dom'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'

// Components
import Routes from './Routes'
import HeaderBar from 'components/HeaderBar'

// import 'styles/shared/app.scss'

const Container = (props: Props) => (
  <div>
  <HeaderBar />
    <div className='app-drawer-container'>
    </div>
    {/* <div className='app-sub-container'> */}
      <div className='app-content'>
        <Switch>{[...Routes]}</Switch>
      </div>
    {/* </div> */}
  </div>
)

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

export default connect(mapStoreToProps)(Container)
