import { Credentials } from 'api/auth/types'

export type AuthData = {
  token: string
}

export enum StorageKeys {
  AuthData = 'techagr@authdata',
  Locale = 'techagr@locale',
  Token = 'techagr@token',
  UnitySystem = 'techagr@unitysystem',
  Theme = 'techagr@theme'
}
