import {all, takeLatest} from 'redux-saga/effects'

import {workerGetPowerBI} from './sagas'

import {PowerBiConstants} from './types'

export function* watcherGetPowerBI () {
    yield takeLatest(PowerBiConstants.GET_POWERBI, workerGetPowerBI)
}


export default function* powerbiWatchers () {
    yield all([watcherGetPowerBI()])
}
